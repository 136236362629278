import "../styles/main.scss"
import React from "react"
import Helmet from 'react-helmet';

import image from '../assets/d01.jpg'
import favicon from '../assets/favicon.ico'

class IndexComponent extends React.Component {
  componentDidMount() {
    setTimeout(() => {
        document.querySelector('.splash').classList.add('splash--is-active');
    }, 500);
  }
  
  render() {
    return (
      <div className="splash">
        <Helmet>
          <title>Derek Nelson Photography</title>
          <meta name="title" content="Derek Nelson Photography" />
          <meta name="description" content="Full site coming soon" />
          <link rel="shortcut icon" href={favicon} />
        </Helmet>   

        <div className="splash">               
          <div className="splash__content">
            <h4 className="hug">Derek Nelson Photography</h4>           
        
            <div className="splash__image">
              <img src={image} alt="Derek Nelson" aria-label="Derek Nelson" />
            </div>  

            <div className="splash__info">
              <div><em>Mount Olstind — Lofoten, Norway</em></div>            
              <div className="contact">
                <h4><a href="mailto:dereknelson87@yahoo.com" target="_blank" rel="noopener noreferrer">Email</a></h4>
                <h4><a href="https://www.instagram.com/dereknelsonphotos/" target="_blank" rel="noopener noreferrer">Instagram</a></h4>
              </div>
            </div>          
          </div>
        </div>
      </div>
    )
  }
}

export default IndexComponent
